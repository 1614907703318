import React, {useState, useEffect} from 'react'
import ReactDOM from 'react-dom/client'
import {useDispatch} from 'react-redux'
import {BrowserRouter as Router, Link, useLocation, useHistory, Route} from 'react-router-dom'
import {CookiesProvider} from 'react-cookie'
import {Provider} from 'react-redux'
import store from './redux/store'
import Cast from './cast/Cast'

import './styles.css'
import {setEnv, setPush} from './redux/actions'
import {subscribeUserToPush} from './push'

function useQuery() {
  const {search} = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

function HomeWithParams() {
  let query = useQuery()
  let dispatch = useDispatch()

  const [screenWidth, setWidth] = useState(window.innerWidth)
  const [screenHeight, setHeight] = useState(window.innerHeight)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
    setHeight(window.innerHeight)
  }

  useEffect(() => {
    pushNotif()
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  let pushNotif = () => {
    //dispatch(setPush('1'))
    navigator.serviceWorker?.ready
      .then(reg => {
        //dispatch(setPush('2'))
        reg.pushManager
          .getSubscription()
          .then(subscription => {
            const options = subscription.options
            //already has key
            //console.log('publicKey', options.applicationServerKey) // the public key
            dispatch(setPush(options.applicationServerKey?.byteLength))

            navigator.setAppBadge(2)
          })
          .catch(err => {
            console.log('err2')
          })
      })
      .catch(err => {
        console.log('err1')
      })

    //2

    dispatch(setPush('0'))
    if ('serviceWorker' in navigator) {
      dispatch(setPush('1'))
      window.addEventListener('load', () => {
        dispatch(setPush('2'))
        navigator.serviceWorker
          .register('/sw.js')
          .then(registration => {
            //console.log('SW registered:', registration)
            dispatch(setPush('registered'))
          })
          .catch(registrationError => {
            console.log('SW registration failed:', registrationError)
            dispatch(setPush('err'))
          })
      })
    }
  }

  const isMobile = screenWidth <= 768

  dispatch(setEnv(isMobile, screenWidth, screenHeight))

  return (
    <CookiesProvider>
      <Home />
    </CookiesProvider>
  )
}

const App = () => (
  <Router>
    <Route path="/:dir">
      <Cast />
    </Route>
    <Route exact path="/">
      <Cast />
    </Route>
  </Router>
)
/*
const App = () => (
  <Router>
    <Route path="/:dir">
      <HomeWithParams />
    </Route>
    <Route exact path="/">
      <HomeWithParams />
    </Route>
  </Router>
)
*/

const root = ReactDOM.createRoot(document.getElementById('app'))
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
)
module?.hot?.accept()


import React, { useEffect, useRef, useState } from 'react'
import { teams } from './teams'


const Cast = () => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const streamUrl = "https://prod-44-211-118-216.amperwave.net/audacy-wjfkfmaac-imc"; // Replace with your stream URL

  const [selectedTeam, setSelectedTeam] = useState(null);


const [volume, setVolume] = useState(1);

const handleVolumeChange = (event) => {
  const newVolume = parseFloat(event.target.value);
  setVolume(newVolume);
  if (audioRef.current) {
    audioRef.current.volume = newVolume;
  }
};
const renderVolumeSlider = () => {
  return (
    <div style={{ position: 'fixed', bottom: '20px', left: '20px', right: '20px', width: 500 }}>
      <input
        type="range"
        min="0"
        max="1"
        step="0.01"
        value={volume}
        onChange={handleVolumeChange}
        style={{ width: '100%', backgroundColor: 'black' }}
      />
    </div>
  );
};

  const playTeamStream = (team) => {
    if (selectedTeam === team && isPlaying) {
      audioRef.current.pause();
      setIsPlaying(false);
    } else {
      setSelectedTeam(team);
      audioRef.current.src = team.url || streamUrl;
      audioRef.current.play();
      setIsPlaying(true);
    }
  };
  const renderTeamButtons = () => {
    return teams.map((team) => (
      <button
        key={team.team}
        onClick={() => playTeamStream(team)}
        style={{
          margin: '5px',
          padding: '10px',
          backgroundColor: selectedTeam === team ? '#4CAF50' : team.url ? '#FFF' : '#888',
          color: selectedTeam === team ? 'white' : 'black',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div>{team.team}</div>
        <div style={{ fontSize: '0.8em', marginTop: '5px' }}>{team.stations[0]}</div>
        {selectedTeam === team && isPlaying ? ' (Playing)' : ''}
      </button>
    ));
  };

  


  const local = {
    page: { alignSelf: 'stretch', display: 'flex', flex: 1, flexWrap:'wrap' }
  }

  return (<div style={local.page}>
    <div>ola</div>
    <audio ref={audioRef} src={streamUrl} />
      {renderTeamButtons()}
      {renderVolumeSlider()}
    </div>
  )
}

export default Cast

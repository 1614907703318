export const teams = [
    {
      "team": "Colorado Rockies",
      "stadium": "Coors Field",
      "city": "Denver, CO",
      "stations": [
        "1430 The Bet",
      ],
      url:"https://prod-44-202-242-101.amperwave.net/audacy-kampamaac-imc?"
    },
    {
      "team": "Colorado Rockies 2",
      "stadium": "Coors Field",
      "city": "Denver, CO",
      "stations": [
        "104.3 The Fan",
        "Mile High Sports Radio"
      ],
      url:"https://bonneville.cdnstream1.com/2612_48.aac"
    },
    {
      "team": "Colorado Rockies 3",
      "stadium": "Coors Field",
      "city": "Denver, CO",
      "stations": [
        "Mile High Sports Radio"
      ],
      url:"https://www.streamcontrol.net:8444/s/12230"
    },
    {
      "team": "Philadelphia Phillies",
      "stadium": "Citizens Bank Park",
      "city": "Philadelphia, PA",
      "stations": [
        "Fox Sports The Gambler",
        "Fox Sports 1350 The Gambler"
      ]
    },
   
    {
      "team": "Detroit Tigers",
      "stadium": "Comerica Park",
      "city": "Detroit, MI",
      "stations": [
        "The Bet Detroit",
      ],
      url:' https://live.amperwave.net/manifest/audacy-wxythd3aac-imc'
    },
    {
      "team": "Detroit Tigers 2",
      "stadium": "Comerica Park",
      "city": "Detroit, MI",
      "stations": [
        "100.9 The Mitt"
      ],
      url:'https://vobook.ru/http://quarrel.str3am.com:7150/live'
    },
    {
      "team": "Cincinnati Reds",
      "stadium": "Great American Ball Park",
      "city": "Cincinnati, OH",
      "stations": [
        "Cat Sports 93.3 & 1340",
        "ESPN-WING 1410"
      ],
      url:'https://ice23.securenetsystems.net/WCMIAM'
    },
    {
      "team": "Arizona Diamondbacks",
      "stadium": "Chase Field",
      "city": "Phoenix, AZ",
      "stations": [
        "1580 The Fanatic",
        "Fox Sports 910 Phoenix",
        "Wildcats Radio 1290"
      ],
      url:"https://ice41.securenetsystems.net/KQFN"
    },
    {
      "team": "New York Yankees",
      "stadium": "Yankee Stadium",
      "city": "New York, NY",
      "stations": [
        "WFAN 101.9 FM & 66 AM",
        "Fox Sports New Jersey",
        "104.5 The Team ESPN"
      ]
    },
    {
      "team": "New York Mets",
      "stadium": "Citi Field",
      "city": "New York, NY",
      "stations": [
        "WFAN 101.9 FM & 66 AM"
      ],
      url:"https://prod-3-236-187-244.amperwave.net/audacy-wfanamaac-imc"
    },
    {
      "team": "St. Louis Cardinals",
      "stadium": "Busch Stadium",
      "city": "St. Louis, MO",
      "stations": [
        "590 The Fan",
        "101 ESPN"
      ],
      url:'https://ice8.securenetsystems.net/1108'
    },
    {
      "team": "Houston Astros",
      "stadium": "Minute Maid Park",
      "city": "Houston, TX",
      "stations": [
        "SportsRadio 610",
        "Fox Sports 610",
        "The Bet Houston"
      ],
      url:'https://prod-3-80-248-42.amperwave.net/audacy-kiltamaac-imc',
    },
    {
      "team": "Houston Astros2",
      "stadium": "Minute Maid Park",
      "city": "Houston, TX",
      "stations": [
        "The Bet Houston"
      ],
      url:'https://prod-54-236-110-141.amperwave.net/audacy-kikkamaac-imc'
    },
    {
      "team": "San Francisco Giants",
      "stadium": "Oracle Park",
      "city": "San Francisco, CA",
      "stations": [
        "Sports Byline USA",
        "KNBR 104.5 FM/680 AM"
      ]
    },
    {
      "team": "San Diego Padres",
      "stadium": "Petco Park",
      "city": "San Diego, CA",
      "stations": [
        "97.3 The Fan",
        "The Bet San Diego"
      ],
      url:'https://live.amperwave.net/direct/audacy-kwfnfmaac-imc'
    },
    {
      "team": "Chicago Cubs",
      "stadium": "Wrigley Field",
      "city": "Chicago, IL",
      "stations": [
        "670 The Score",
        "ESPN 1000"
      ],
      url:'https://prod-44-192-113-78.amperwave.net/audacy-wscramaac-imc'
    },
    {
      "team": "Chicago White Sox",
      "stadium": "Guaranteed Rate Field",
      "city": "Chicago, IL",
      "stations": [
        "670 The Score",
        "ESPN 1000"
      ],
      url:'https://prod-44-192-113-78.amperwave.net/audacy-wscramaac-imc?session-id=1118e350c52975615d5594edfefd047d'

    },
    {
      "team": "Pittsburgh Pirates",
      "stadium": "PNC Park",
      "city": "Pittsburgh, PA",
      "stations": [
        "93.7 The Fan"
      ],
      url:'https://live.amperwave.net/direct/audacy-kdkafmaac-imc'
    },
    {
      "team": "Boston Red Sox",
      "stadium": "Fenway Park",
      "city": "Boston, MA",
      "stations": [
        "WEEI Sports Radio Network",
        "98.5 The Sports Hub"
      ]
    },
    {
      "team": "Los Angeles Dodgers",
      "stadium": "Dodger Stadium",
      "city": "Los Angeles, CA",
      "stations": [
        "AM 570 LA Sports"
      ]
    },
    {
      "team": "Seattle Mariners",
      "stadium": "T-Mobile Park",
      "city": "Seattle, WA",
      "stations": [
        "Sports Radio 93.3 KJR",
        "Sports Radio 950 KJR",
        "Seattle Sports 710 AM"
      ]
    },
    {
      "team": "Texas Rangers",
      "stadium": "Globe Life Field",
      "city": "Dallas/Fort Worth, TX",
      "stations": [
        "105.3 The Fan",
        "KMHT 103.9 FM"
      ]
    },
    {
      "team": "Miami Marlins",
      "stadium": "loanDepot park",
      "city": "Miami, FL",
      "stations": [
        "AM 560 Sports WQAM",
        "Fox Sports 640 South Florida",
        "1230 The Gambler"
      ]
    },
    {
      "team": "Baltimore Orioles",
      "stadium": "Oriole Park at Camden Yards",
      "city": "Baltimore, MD",
      "stations": [
        "105.7 The Fan",
        "The Bet Baltimore"
      ],
      url:'https://live.amperwave.net/direct/audacy-wjzfmaac-imc'
    },
    {
        "team": "Los Angeles Angels",
        "stadium": "Angel Stadium",
        "city": "Anaheim, CA",
        "stations": [
          "Angels Radio AM 830"
        ],
        url:'https://klaa.streamguys1.com/live-aac'
      },
      {
        "team": "Minnesota Twins",
        "stadium": "Target Field",
        "city": "Minneapolis, MN",
        "stations": [
          "KFAN 100.3 FM",
          "KFAN Plus"
        ]
      },
      {
        "team": "Kansas City Royals",
        "stadium": "Kauffman Stadium",
        "city": "Kansas City, MO",
        "stations": [
          "96.5 The Fan",
          "Sports Radio 810 WHB"
        ],
        url:"https://live.amperwave.net/manifest/audacy-kfnzfmaac-imc"
      },
      {
        "team": "Cleveland Guardians",
        "stadium": "Progressive Field",
        "city": "Cleveland, OH",
        "stations": [
          "92.3 The Fan",
          "ESPN 850 Cleveland",
        ],
        url:"https://prod-3-87-222-146.amperwave.net/audacy-wkrkfmaac-imc"
      },
      {
        "team": "Tampa Bay Rays",
        "stadium": "Tropicana Field",
        "city": "St. Petersburg, FL",
        "stations": [
          "95.3 WDAE"
        ]
      },
      {
        "team": "Atlanta Braves",
        "stadium": "Truist Park",
        "city": "Atlanta, GA",
        "stations": [
          "680 The Fan / 93.7 FM",
          "92.9 The Game"
        ],
        url:'https://stream.zeno.fm/q9458433dm8uv'
      },
      {
        "team": "Toronto Blue Jays",
        "stadium": "Rogers Centre",
        "city": "Toronto, ON",
        "stations": [
          "No stations currently listed"
        ]
      },
      {
        "team": "Washington Nationals",
        "stadium": "Nationals Park",
        "city": "Washington, D.C.",
        "stations": [
          "106.7 The Fan",
        ],
        url:"https://live.amperwave.net/direct/audacy-wjfkfmaac-imc"
      },
      {
        "team": "Oakland Athletics",
        "stadium": "Oakland Coliseum",
        "city": "Oakland, CA",
        "stations": [
          "95.7 The Game"
        ],
        url:'https://prod-44-201-33-194.amperwave.net/audacy-kgmzfmaac-imc?session-id=4a843c0e8da5ed06766970c479aad1bf'
      },
      {
        "team": "Milwaukee Brewers",
        "stadium": "American Family Field",
        "city": "Milwaukee, WI",
        "stations": [
          "97.3 The Game",
          "94.5 ESPN",
        ],
        url:"https://stream.revma.ihrhls.com/zc2685"
      }
]

